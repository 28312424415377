@import '~@ngx-share/button/themes/circles/circles-dark-theme.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* deeppurple-amber.css
indigo-pink.css
pink-bluegrey.css
purple-green.css */


body,
html {
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-size: 13px;
  color: #333333;
  font-family: 'Source Sans Pro', sans-serif;
  background: #FFFFFF !important;
  font-weight: normal;

  animation: grow 4s infinite;
}

@keyframes grow {
  0%, 25%, 100% {
    height: 70vh;
 }
  50%, 75% {
    height: 110vh;
 }
}

a {
  text-decoration: none !important;
  transition: 0.3s;
}

button {
  transition: 0.3s;
  border: 0;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
}

li {
  display: block;
  float: left;
}

li a {
  display: block;
}

.register {
  background: url("./assets/images/registation.png");
  width: 100%;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 200px 0;
}

.list-banner {
  background: url("./assets/images/home_slider.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.dtls-bnr {
  position: relative;
  width: 100%;
  background-image: url("./assets/images/details-banner.jpg");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}



.gallery .owl-prev {
  color: #fff;
  background-image: url("./assets/images/owl-prvw.png");
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  background-size: contain;
  margin-right: 10px;
}

.gallery .owl-next {
  color: #fff;
  background-image: url("./assets/images/owl-next.png");
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  background-size: contain;
}

.icheck-material-pink label {
  line-height: 17px !important;
}

.mat-radio-outer-circle {
  width: 17px !important;
  border-width: 1px !important;
  height: 17px !important;
}

.mat-radio-inner-circle {
  width: 17px !important;
  height: 17px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #de4f1f !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #de4f1f !important;
}

.mat-radio-outer-circle {
  border-color: rgb(222, 79, 31) !important;
}

.mat-radio-label-content {
  font-weight: normal;
}

.mat-radio-button .mat-radio-ripple {
  height: 37px !important;
  width: 37px !important;
}

.displayNone {
  display: none !important;
}

.mat-checkbox label {
  font-weight: normal;
  font-size: 13px;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

/*Menu Page*/
.tb-dtls {
  padding: 20px;
  width: 100%;
  border-radius: 3px;
}

.tb-dtls .mat-expansion-panel {
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.tb-dtls .mat-expansion-panel-header {
  padding: 0 15px;
}

.tb-dtls i {
  font-size: 20px;
}

.tb-dtls .mat-content {
  font-size: 13px;
}

.mat-radio-label-content {
  padding-left: 0px !important;
}

.mat-rdo .mat-radio-button {
  margin-right: 10px;
}

.mat-rdo .mat-radio-button:last-child {
  margin-right: 0px;
}

/*
.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform 0.25s linear;
  -webkit-transition: -webkit-transform 0.25s linear;
}

.panel-default>.panel-heading a[aria-expanded="true"] {
  background-color: #fcfcfc;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
  background: url("./assets/images/down-arrow.png");
  background-repeat: no-repeat;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  background: url("./assets/images/up-arrow.png");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
} */
/*
.accordion-option {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px 0;
}

.accordion-option .title {
  font-size: 20px;
  font-weight: bold;
  float: left;
  padding: 0;
  margin: 0;
}

.accordion-option .toggle-accordion {
  float: right;
  font-size: 16px;
  color: #6a6c6f;
}

.accordion-option .toggle-accordion:before {
  content: "Expand All";
}

.accordion-option .toggle-accordion.active:before {
  content: "Collapse All";
} */

.mat-expansion-panel-body {
  padding: 0 16px 16px !important;
}

.form-group .mat-form-field {
  display: block !important;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }

  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
}

@keyframes scaleForward {
  0% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes slideUpLarge {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDownLarge {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(150px);
  }
}

@keyframes blowUpContent {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  99.9% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    transform: scale(0);
  }
}

@keyframes blowUpContentTwo {
  0% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }

  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }

  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

@keyframes roadRunnerOut {
  0% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }

  30% {
    transform: translateX(-30px) skewX(-5deg) scaleX(0.9);
  }

  100% {
    transform: translateX(1500px) skewX(30deg) scaleX(1.3);
  }
}

@keyframes sketchIn {
  0% {
    stroke-dashoffset: 778;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes sketchOut {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 778;
  }
}

@keyframes modalFadeIn {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: white;
  }
}

@keyframes modalFadeOut {
  0% {
    background-color: white;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes modalContentFadeIn {
  0% {
    opacity: 0;
    top: -20px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes modalContentFadeOut {
  0% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -20px;
  }
}

@keyframes bondJamesBond {
  0% {
    transform: translateX(1000px);
  }

  80% {
    transform: translateX(0px);
    border-radius: 75px;
    height: 75px;
    width: 75px;
  }

  90% {
    border-radius: 3px;
    height: 182px;
    width: 247px;
  }

  100% {
    border-radius: 3px;
    height: 162px;
    width: 227px;
  }
}

@keyframes killShot {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(300px) rotate(45deg);
    opacity: 0;
  }
}

@keyframes fadeToRed {
  0% {
    box-shadow: inset 0 0 0 rgba(201, 24, 24, 0.8);
  }

  100% {
    box-shadow: inset 0 2000px 0 rgba(201, 24, 24, 0.8);
  }
}

@keyframes slowFade {
  0% {
    opacity: 1;
  }

  99.9% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

/*====LOGIN==========*/

modal {
  text-align: left;
}

.modal-content {
  border: none;
  border-radius: 2px;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.modal-header {
  border-bottom: 0;
  padding: 15px 26px 0;
}

.modal-title {
  font-size: 34px;
}

.modal-body {
  border-bottom: 0;
  padding: 5px 26px 10px;
  font-size: 15px;
}

.modal-footer {
  border-top: 0;
  padding: 0 26px 26px;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

/* modal animation */

.modal-content {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
  background: hsla(0, 0%, 100%, 0.44) !important;
  border: 1px solid hsla(0, 0%, 100%, 0.44) !important;
}

#paypal-button-container .zoid-outlet {
  width: 100% !important;
  height: 46px !important;
}

.black-overlay {
  position: fixed !important;
}

button {
  outline: none !important
}

.mat-datepicker-toggle {
  float: right;
  margin-top: -44px;
}

.sbm-btn {
  background: #f44335 !important;
  color: #fff !important;
}

mat-ink-bar {
  display: none !important;
}

.mat-chip {
  font-size: 15px !important;
}

.tooltip-arrow:before {
  display: none;
}

.close {
  position: absolute;
  top: 18px;
  right: 20px;
  color: #fff;
  opacity: 1 !important;
}

.mat-inline i {
  float: left;
  margin-right: 10px;
  color: #e54c29;
}

.city h4 {
  color: #333333;
  font-size: 40px;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
}

.city p {
  margin: 0px;
  margin-bottom: 30px;
}

.city ul {
  width: 100%;
}

.city ul li {
  width: 33.3333%;
  margin-bottom: 10px;
}

.city ul li a {
  color: #333333;
  padding: 5px 0px;
  transition: 0.3s;
}

.city ul li a:hover {
  padding-left: 10px;
  color: #e54c2a;
}

.city ul li a span {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
}

#cty-cuisn {
  padding-bottom: 80px;
  width: 100%;
  overflow: hidden;
}

#cty-cuisn {
  background-repeat: no-repeat;
  background-position: -655px 41px;
}

.mat-tab-label {
  box-shadow: 0px 0px 10px rgba(16, 14, 14, 0.1411764705882353);
  border-radius: 30px;
  height: 40px !important;
  margin-right: 15px;
  padding: 0px !important;
  opacity: 1 !important;
}

.mat-tab-labels {
  padding: 10px;
}

.mat-tab-label .mat-tab-label-content {
  width: 100%;
}

.mat-tab-label .mat-tab-label-content a {
  width: 100%;
  outline: none;
  border-radius: 0px !important;
}

.mat-tab-label .mat-tab-label-content a span {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.mat-tab-label .mat-tab-label-content a span small {
  display: block;
  width: 8px;
  height: 8px;
  background: #e64c27;
  border-radius: 100%;
  margin-right: 15px;
}

.mat-tab-label .mat-tab-label-content a span span {
  position: relative;
  transition: 0.3s;
  width: auto;
  display: block;
  margin: 0px 10px;
}

.mat-tab-label .mat-tab-label-content a span span:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  background: #000;
}

.mat-tab-label .mat-tab-label-content a span span:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  background: #000;
  transform: rotate(90deg);
}

.mat-tab-label-container {
  width: 87%;
}

.mat-tab-header-pagination {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(229, 76, 40, 0.18);
  box-shadow: none !important;
}

.mat-tab-header-pagination-chevron {
  border-color: rgb(229, 76, 40);
}

.mat-tab-header {
  align-items: center;
}

.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-tab-label .mat-tab-label-content a span:hover {
  background: #e54c28;
  color: #fff;
}

.mat-tab-label .mat-tab-label-content a:hover span small {
  background: #fff;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: #e54c28;
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgb(229, 76, 40);
}

.mat-card {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
}

.mat-card-header-text {
  margin-left: 0px !important;
}

/*New Popup Design*/
.new-pop {
  position: relative;
}

.new-pop h1 {
  font-size: 25px;
  color: #000;
  margin-bottom: 15px;
}

.new-pop .form-group input {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  outline: none !important;
  font-size: 14px;

}

.new-pop .form-group textarea {
  width: 100%;
  font-size: 14px;
  border-radius: 3px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  outline: none !important;
  height: 100px;

}

.cmn-button {
  background: #f44335 !important;
  color: #fff !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 15px 15px !important;
  font-size: 16px;
  cursor: pointer;
}

.pop-cls {
  position: absolute;
  top: 0px;
  right: 0px;
}

.pop-cls svg {
  width: 15px;
  height: 15px;
}

.form-group {
  position: relative;
}

.form-group .lft-icn {
  position: absolute;
  top: 14px;
  left: 12px;
}

.form-group .lft-icn svg {
  width: 20px;
  height: 20px;
  fill: #FF5722;
}

/*New Popup Design*/

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.57);
}

.icheck-material-pink label {
  font-size: 14px;
  color: #000;
}

.icheck-material-pink>input:first-child:checked+label::before,
.icheck-material-pink>input:first-child:checked+input[type="hidden"]+label::before {
  background-color: #F44336;
  border-color: #F44336;
}

.pop-cls {
  outline: none !important;
}

.icheck-material-pink>input:first-child:not(:checked):not(:disabled):hover+label::before,
.icheck-material-pink>input:first-child:not(:checked):not(:disabled):hover+input[type="hidden"]+label::before {
  border-color: #ee5623;
}

[class*="icheck-material"]>input:first-child:not(:checked):not(:disabled):hover+label::before,
[class*="icheck-material"]>input:first-child:not(:checked):not(:disabled):hover+input[type="hidden"]+label::before {
  border-width: 1px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #e64c29;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #e64c29;
}

.option-cmnt textarea {
  padding: 15px 15px;
}

.pk-dl ul li .mat-radio-label {
  margin-bottom: 0px;
}

.pk-dl ul li mat-radio-button {
  font-size: 16px;
}

.mat-radio-button {
  font-family: 'Source Sans Pro', sans-serif !important;
}

input {
  width: 100%;
  height: 50px;
  border-radius: 3px !important;
  border: 1px solid #ccc !important;
  font-size: 16px;
  color: #000;
  padding: 10px 15px;
  outline: none;
  box-shadow: none !important;
}

select {
  width: 100%;
  height: 50px;
  border-radius: 3px !important;
  border: 1px solid #ccc !important;
  font-size: 16px;
  color: #000;
  padding: 10px 15px;
  outline: none;
  box-shadow: none !important;
}

textarea {
  width: 100%;
  height: 100px;
  border-radius: 3px !important;
  border: 1px solid #ccc !important;
  font-size: 16px;
  color: #000;
  padding: 10px 15px;
  outline: none;
  box-shadow: none !important;
}

.mat-calendar-body-selected {
  background-color:
    #e64c29 !important;
  color: #fff;
}

.form-group i {
  cursor: pointer !important;
}

.mat-datepicker-toggle-active {
  color: #e64c29 !important;
}


.mat-chip-list-wrapper {
  justify-content: center !important;
}

/*responsive*/
@media (max-width: 767px) {
  .new-pop h1 {
    font-size: 20px;
  }

  .cdk-overlay-container {
    overflow: scroll;
  }

  .inner-page ul {
    margin-top: 6px !important;

  }

  .container {
    max-width: 100%;
  }


  .pmnt-icn li img {
    object-fit: cover;
  }

  .pmnt-icn {
    overflow: hidden;
  }

  .mat-tab-header-pagination {
    width: 34px;
    height: 33px;
  }

  .mat-tab-label .mat-tab-label-content a span {
    font-size: 12px;
    color: #333333;
  }

  .dtls-bnr {
    margin-bottom: 20px;
  }

  .tb-dtls {
    margin-bottom: 20px;
  }
}

@media (max-width: 580px) {

  share-buttons {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .new-pop h1 {
    font-size: 17px;
  }

  .price-add span {
    padding-left: 0px;
  }

  .cmn-button {
    height: 40px !important;
    font-size: 14px !important;
    padding: 9px 15px !important;
  }

  .col-xs-lg-12 {
    width: 100%;
  }
}

@media (max-width: 400px) {
  #paypal-button-container {
    width: 100%;
    display: inline-block;
  }
}

.icheck-material-pink>input[type="radio"]:first-child:checked+label::after,
.icheck-material-pink>input[type="radio"]:first-child:checked+input[type="hidden"]+label::after {
  background-color: #f44336 !important;
}
